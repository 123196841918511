import React from "react";
import { NavLink, Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="text-center pb-4" style={{ backgroundColor: "#F8F8FE" }}>
      <div>
        <Link to="https://www.tobtek.com" style={{textDecoration:"none"}}>
          <p
            style={{
              cursor: "pointer",
              fontSize: 12,
              marginBottom: 0,
              color: "black",
            }}
            className="float-right"
          >
            © 2022-23 TOBTEK
          </p>
        </Link>
      </div>

     
    </div>
  );
};

export default Footer;
